import { Typography, Icon } from "@material-ui/core";
import { formatDate } from "app/main/utils/dateUtils";
import { useMemo } from "react";
import { useSnackbar } from "notistack";

import { initiatePhoneCall } from "app/services/brokersServices";
import { isEnabled } from "app/services/featureStorageService";
import { showSnackbar } from "app/main/utils/snackbarUtil";

function LoadSearchSummaryBrokerView(props) {
	const load = props.load;
	const carrierId = props.carrierId;
	const searchId = props.searchId;
	const truckId = props.truckId;
	const userId = props.userId;
	const onShowBrokerSummary = props.onShowBrokerSummary;
	const snackbar = useSnackbar();

	// Regex to check if the contact is a phone number with extension Ex. 1234567890 ext 1234 and not an email
	const isPhone = load?.contact?.match(/^[0-9]+(?:\s*(?:ext|x)\s*[0-9]+)?$/) ? true : false;

	const brokerLink = useMemo(() => {
		if (!load?.contact?.includes("@")) return `tel:${load?.contact}`;

		const newLine = `%0D%0A`;
		const mailSubject = load?.hasBrokerId
			? `Load ${load?.load_id}`
			: `Load from ${load?.origin} to ${load?.destination}`;
		const mailBody = `Hi,${newLine}${newLine}I'm interested in load ${
			load?.hasBrokerId ? `ID ${load?.load_id}` : ""
		} from ${load?.origin} to ${load?.destination} on ${formatDate(load.ready)}.${newLine}${newLine}Thanks`;
		return `mailto:${load?.contact}?subject=${mailSubject}&body=${mailBody}`;
	}, [load]);

	const handleCallContact = async () => {
		if (load?.contact) {
			try {
				await initiatePhoneCall(userId, load.contact, load?.tripid, carrierId, searchId, truckId);
				// Handle successful call initiation (e.g., show a success message)
				console.log("Call initiated successfully");
				showSnackbar(snackbar, "Call initiated successfully", "success");
			} catch (error) {
				// Handle error (e.g., show an error message)
				console.error("Failed to initiate call:", error);
				showSnackbar(snackbar, "Failed to initiate call", "error");
			}
		}
	};

	return (
		<div className={"flex flex-col mb-28 -mt-10 "}>
			<div className={"flex flex-row border-b-1 border-grey-300 mt-10"}>
				<Typography className="flex text-black text-12 ml:text-13 break-normal">Name</Typography>
				<Typography
					className={`text-black text-12 ml:text-13 w-full ${
						props?.isPublicView ? "" : "cursor-pointer text-blue-700"
					}`}
					style={{ textAlign: "end", padding: 0 }}
					onClick={props?.isPublicView ? null : onShowBrokerSummary}
				>
					{load?.clientid && load?.clientidOverride
						? `${load?.clientid} (${load?.clientidOverride})`
						: load?.clientid ?? "No Information"}
					{load?.clientid && <Icon className="text-15 ml-4 -mb-2">info</Icon>}
				</Typography>
			</div>
			<div className={"flex flex-row border-b-1 border-grey-300 mt-10"}>
				<Typography className="flex w-full text-black text-12 ml:text-13 break-normal">Contact</Typography>
				{load?.contact ? (
					<div className="flex items-center">
						<Typography
							onClick={() => window.open(brokerLink)}
							className={`text-12 ml:text-13 cursor-pointer text-blue-700 mr-2`}
							style={{ textAlign: "end", padding: 0 }}
						>
							{load?.contact}
						</Typography>
						{isEnabled("ENABLE_PHONE_CALLS") && isPhone && (
							<Icon className="text-15 cursor-pointer text-blue-700" onClick={handleCallContact}>
								phone
							</Icon>
						)}
					</div>
				) : (
					"-"
				)}
			</div>
		</div>
	);
}

export default LoadSearchSummaryBrokerView;
